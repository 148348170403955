import React, { useState, useEffect } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./locationfind.scss";

// Fix for missing marker icons in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const fromLocation = { lat: 12.97285, lng: 77.65198 };

const App = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [distance, setDistance] = useState(null);
  const [mapView, setMapView] = useState("road");
  const [fromAddress, setFromAddress] = useState("");
  const [userAddress, setUserAddress] = useState("");

  useEffect(() => {
    // Get initial addresses
    fetchAddress(fromLocation, setFromAddress);

    // Watch user's location in real-time
    if ("geolocation" in navigator) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = { lat: latitude, lng: longitude };
          setUserLocation(currentLocation);
          calculateDistance(fromLocation, currentLocation);
          fetchAddress(currentLocation, setUserAddress);
        },
        (error) => {
          alert("Unable to fetch location. Please allow location access.");
          console.error(error);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 10000,
          timeout: 5000,
        }
      );

      // Cleanup on component unmount
      return () => navigator.geolocation.clearWatch(watchId);
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  }, []);

  const fetchAddress = (location, setAddress) => {
    const { lat, lng } = location;
    fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`)
      .then((response) => response.json())
      .then((data) => {
        setAddress(data.display_name || "Address not found");
      })
      .catch(() => setAddress("Unable to fetch address"));
  };

  const calculateDistance = (from, to) => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = ((to.lat - from.lat) * Math.PI) / 180;
    const dLng = ((to.lng - from.lng) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((from.lat * Math.PI) / 180) *
      Math.cos((to.lat * Math.PI) / 180) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInKm = R * c;
    setDistance({
      km: distanceInKm.toFixed(2),
      meters: (distanceInKm * 1000).toFixed(0),
    });
  };

  const handleMapViewChange = (event) => {
    setMapView(event.target.value);
  };

  const mapLayers = {
    road: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    satellite: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    terrain: "https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png",
  };

  return (
    <div id="Container" style={{ display: "flex", height: "100vh" }}>
      {/* Left Container */}
      <div style={{ flex: 1, padding: "10px", borderRight: "1px solid #ccc" }}>
        <h3>From Location</h3>
        <p>Lat: {fromLocation.lat}</p>
        <p>Lng: {fromLocation.lng}</p>
        <p>Address: {fromAddress || "Fetching address..."}</p>
        <Map
          style={{ height: "200px", marginTop: "10px" }}
          center={fromLocation}
          zoom={13}
        >
          <TileLayer url={mapLayers[mapView]} />
          <Marker position={fromLocation}>
            <Popup>From Location</Popup>
          </Marker>
        </Map>
      </div>

      {/* Center Container */}
      <div style={{ flex: 1, padding: "10px", borderRight: "1px solid #ccc" }}>
        <h3>Map View</h3>
        <select onChange={handleMapViewChange} style={{ marginBottom: "20px" }}>
          <option value="road">Road</option>
          <option value="satellite">Satellite</option>
          <option value="terrain">Terrain</option>
        </select>
        <h3>Distance</h3>
        {distance ? (
          <>
            <p>Distance: {distance.km} km</p>
            <p>Distance: {distance.meters} meters</p>
          </>
        ) : (
          <p>Calculating...</p>
        )}
      </div>

      {/* Right Container */}
      <div style={{ flex: 1, padding: "10px" }}>
        <h3>User Location</h3>
        {userLocation ? (
          <>
            <p>Lat: {userLocation.lat}</p>
            <p>Lng: {userLocation.lng}</p>
            <p>Address: {userAddress || "Fetching address..."}</p>
            <Map
              style={{ height: "200px", marginTop: "10px" }}
              center={userLocation}
              zoom={13}
            >
              <TileLayer url={mapLayers[mapView]} />
              <Marker position={userLocation}>
                <Popup>User Location</Popup>
              </Marker>
            </Map>
          </>
        ) : (
          <p>Waiting for location...</p>
        )}
      </div>
    </div>
  );
};

export default App;